import React from 'react'
import MainLayout from '../layouts/MainLayout'
import ResourcesView from '../views/ResourcesView'

const ResourcesPage = () => {
  return (
    <MainLayout>
      <ResourcesView />
    </MainLayout>
  )
}

export default ResourcesPage
