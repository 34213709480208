import React from 'react'
import PageHeading from '../components/headings/PageHeading'

const ResourcesView = () => {
  return (
    <>
      <PageHeading title="noun:resources" />
    </>
  )
}

export default ResourcesView
